import moment from 'moment';
import { isArrayNotEmpty } from '@/utils/validators';

export const version = '0.3.12';

export const tinyMceAPIKey = 'rc7be6yp1q38alhr546f7snrd78hqz3nqz0zn9ugcacdf94q';

let frontendConfigs = {
  badgeUrl: '',
  portalUrl: '',
};

// eslint-disable-next-line no-return-assign
export const setFrontendConfigs = (config) => frontendConfigs = config;

export const genderTableList = [
  { key: 'MALE', value: 'Uomo' },
  { key: 'FEMALE', value: 'Donna' },
];

export const calculateDaysDiffToday = (endDate) => {
  const startMoment = moment().startOf('day');
  const expireMoment = moment(endDate, 'DD/MM/YYYY').startOf('day');
  return expireMoment.diff(startMoment, 'days');
};

export const calculateDaysDiff = (startDate, endDate) => {
  const startMoment = moment(startDate).startOf('day');
  const expireMoment = moment(endDate, 'DD/MM/YYYY').startOf('day');
  return expireMoment.diff(startMoment, 'days');
};

export const createPublicUri = (url) => encodeURI(`/crp-public/${url}`);
export const createThumbPublicUri = (url) => encodeURI(`/crp-public/${url}.thumb`);

export const createBadgeUri = (badgeCode) => `/qrbadge/${badgeCode}`;
// export const createBadgeUri = (badgeCode) => {
//   if (isPresent(badgeCode)) {
//     return createBadgeUri(badgeCode);
//   }
//   return '';
// };
export const createBadgeCompleteUrl = (badgeCode) => `${frontendConfigs.badgeUrl}/badge/${badgeCode}`;

export const sortSelectList = (selectList) => {
  if (!isArrayNotEmpty(selectList)) {
    return [];
  }

  return selectList.sort((a, b) => a.key > b.key);
};

export const decodeTableListValue = (tableList, code) => {
  const item = tableList.filter((value) => value.key === code);
  return item.length > 0 ? item[0].value : code;
};
